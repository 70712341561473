<template>
  <b-card
    no-body
    class="deposit-list"
  >
    <div class="m-2">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Channel Name:</label>
            <v-select
              v-model="channelName"
              :options="channelNameOption"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="All"
              label="channel_name"
            />
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Deposit status:</label>
            <v-select
              v-model="withdrawStatus"
              :options="withdrawStatusOption"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="All"
              label="title"
            />
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Currency:</label>
            <v-select
              v-model="currency"
              :options="currencyOptions"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="Currency"
              label="name"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <label for="inline-form-custom-select-pref">Time:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="timeType.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in timeTypeOptions"
                    :key="index"
                    @click="timeTypeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <div class="time-type">
                <date-picker
                  :time-range-default="timeRangeDefault"
                  @TimeRange="getTimeRange"
                />
              </div>
            </b-input-group>
          </b-col>
          <!-- <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >CreateTime:</label>
            <div>
              <date-picker
                :time-range-default="timeRangeDefault"
                @TimeRange="getTimeRange"
              />
            </div>
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >UpdateTime:</label>
            <div>
              <date-picker
                :time-range-default="timeUpdateDefault"
                @TimeRange="getTimeUpdate"
              />
            </div>
          </b-col> -->
          <b-col md="6">
            <label for="inline-form-custom-select-pref">Search Terms:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="mode.title"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in modeOptions"
                    :key="index"
                    @click="modeOptionChange(item)"
                  >
                    {{ item.title }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchKey"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              class="mr-1 b-col-top"
              @click="search"
            >Search</b-button>
            <b-button
              variant="secondary"
              class="mr-1 b-col-top"
              @click="reset"
            >Reset</b-button>
            <b-button
              variant="success"
              class="b-col-top"
              @click="download"
            >Download</b-button>
          </b-col>
          <!-- <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Mode:</label>
            <v-select
              v-model="mode"
              :options="modeOptions"
              class="invoice-filter-select mb-1 mr-sm-1 no-clear-select"
              placeholder="Transaction ID"
              label="title"
            />
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Search Key:</label>
            <b-form-input
              v-model="searchKey"
              class="d-inline-block mr-1 mb-1"
              placeholder="Search..."
              autocomplete="off"
            />
          </b-col> -->
        </b-row>
      </b-form>
    </div>
    <b-table
      ref="refDepositListTable"
      :items="depositList"
      responsive
      :fields="tableColumns"
      primary-key="transactionId"
      show-empty
      empty-text="No matching records found"
      class="position-relative withdraw-table"
    >
      <!-- Column: Id -->
      <template #cell(transactionId)="data">
        <b-link
          :to="{
            path: '/deposit/deposit-detail',
            query: { id: data.item.transactionId, timezone: timeZone },
          }"
          class="font-weight-bold"
          target="_blank"
        >
          {{ data.value }}
        </b-link>
      </template>
      <!-- Column: uuid -->
      <template #cell(uuid)="data">
        <b-link
          v-hasBtn="`link_user_list`"
          :to="{
            path: '/usercenter/user-detail',
            query: { id: data.item.uuid, timezone: timeZone },
          }"
          class="font-weight-bold"
          target="_blank"
        >
          {{ data.value }}
        </b-link>
        <span v-hasBtn="`no_user_list`">{{ data.value }}</span>
      </template>
      <!-- Column: depositStatus -->
      <template #cell(depositStatus)="data">
        {{ depositStatusOption[data.value] }}
      </template>
      <!-- Column: createdAt -->
      <template #cell(createdAt)="data">
        <span class="text-nowrap"> {{ updateDate(data.value, timeZone) }}</span>
      </template>
      <!-- Column: updatedAt -->
      <template #cell(updatedAt)="data">
        <span class="text-nowrap"> {{ updateDate(data.value, timeZone) }}</span>
      </template>
    </b-table>
    <span v-hasBtn="`deposit`" />
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted mr-1"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
          <span
            class="text-muted"
          >Total Amount {{ dataMeta.totalAmount }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'
import { updateDate, setDatetimeDefault, handlerTimestamp } from '@/libs/utils'
import store from '@/store'
import DatePicker from '@/component/Datepicker.vue'
import useDeposit from './useDeposit'
import depositStoreModule from './depositStoreModule'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')

export default {
  components: {
    BForm,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    DatePicker,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateWallet(['timeZone', 'currencys']),
    currencyOptions() {
      return this.currencys
    },
  },
  watch: {
    timeZone() {
      // this.timezone = value
      // this.timeRange = []
      // // this.timeRange = handlerTimestamp(
      // //   this.timeRangeDefault[0],
      // //   this.timeRangeDefault[1],
      // //   value,
      // // )
      // // this.timeUpdate = handlerTimestamp(
      // //   this.timeUpdateDefault[0],
      // //   this.timeUpdateDefault[1],
      // //   value,
      // // )
      // this.fetchDepositList()
    },
  },
  mounted() {
    this.timezone = this.timeZone
  },
  methods: {
    search() {
      this.currentPage = 1
      this.fetchDepositList()
    },
    reset() {
      this.channelName = { channel_name: 'All', value: '-1' }
      this.withdrawStatus = { title: 'All', value: '-1' }
      this.currency = { code: 'BRL', name: 'BRL' }
      this.timeType = { label: 'CREATE', value: 'create' }
      this.timeRange = []
      this.timeRangeDefault = []
      this.mode = { title: 'TRANSACTION ID', value: '1' }
      this.searchKey = null
    },
    changePagination(page) {
      this.currentPage = page
      this.fetchDepositList()
    },
    download() {
      this.depositDownload(() => this.$router.push({ name: 'Download' }))
    },
    getTimeRange(time) {
      this.timeRange = time
    },
    getTimeUpdate(time) {
      this.timeUpdate = time
    },
  },
  setup() {
    const DEPOSIT_APP_STORE_MODULE_NAME = 'deposit'
    // Register module
    if (!store.hasModule(DEPOSIT_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPOSIT_APP_STORE_MODULE_NAME, depositStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPOSIT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(DEPOSIT_APP_STORE_MODULE_NAME)
      }
    })
    // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
    const modeOptions = [
      { title: 'TRANSACTION ID', value: '1' },
      { title: 'USER ID', value: '3' },
      { title: 'CHANNEL ID', value: '2' },
      { title: 'PHONE', value: '4' },
      { title: 'DOCUMENT ID', value: '5' },
      { title: 'EMAIL', value: '6' },
    ]
    const timeTypeOptions = [
      { label: 'CREATE', value: 'create' },
      { label: 'UPDATE', value: 'update' },
    ]
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refDepositListTable,

      depositStatusOption,
      withdrawStatusOption,
      channelNameOption,

      searchKey,
      mode,
      withdrawStatus,
      channelName,
      timeRange,
      timeRangeDefault,
      timeUpdate,
      timeUpdateDefault,
      timezone,
      currency,
      timeType,

      depositList,
      fetchDepositList,
      depositDownload,
    } = useDeposit()
    const timeTypeOptionChange = item => {
      timeType.value = item
    }
    const modeOptionChange = item => {
      mode.value = item
    }
    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refDepositListTable,
      modeOptions,
      timeTypeOptions,

      depositStatusOption,
      withdrawStatusOption,
      channelNameOption,

      searchKey,
      mode,
      withdrawStatus,
      channelName,
      timeRange,
      timeRangeDefault,
      timeUpdate,
      timeUpdateDefault,
      timezone,
      currency,
      timeType,

      depositList,
      fetchDepositList,
      depositDownload,
      updateDate,

      timeTypeOptionChange,
      modeOptionChange,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;
  width: 100%;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.b-form-flex {
  display: flex;
  flex-direction: row;
}
.b-col-top {
  margin-top: 1.67rem;
}
.deposit-list {
  ::v-deep .withdraw-table {
    .table tr td:nth-of-type(1) {
      word-break: break-all;
      word-wrap: break-all;
      min-width: 10rem;
    }
    .table tr td:nth-of-type(4) {
      min-width: 5rem;
    }
    .table tr td:nth-of-type(9) {
      min-width: 11rem;
    }
    .table tr td:nth-of-type(10) {
      min-width: 11rem;
    }
  }

  .time-type {
    flex: 1;
  }
}
</style>
