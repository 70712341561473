import axios from '@axios'
import $http from '@/libs/http'

export default {
  namespaced: true,
  state: {
    depositStatusOption: null,
    withdrawStatusOption: null,
    channelNameOption: null,
  },
  getters: {},
  mutations: {},
  actions: {
    fetchWalletStatus() {
      return new Promise((resolve, reject) => {
        $http.get('/api/v1/admin/getWalletStatus')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepositList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        $http
          .post('/api/v1/deposit/list', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChannelList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        $http
          .get('/api/v1/deposit/channel_list', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    download(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        $http
          .post('/api/v1/deposit/download', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
