import {
  ref, watch, computed,
  // onMounted,
} from '@vue/composition-api'
import Vue from 'vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import loadingRegister from '@/libs/loading/loading'
import store from '@/store'
import { trim, setDatetimeDefault, handlerTimestamp } from '@/libs/utils'

Vue.use(loadingRegister)

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()
  // 表格
  const refDepositListTable = ref(null)
  const tableColumns = [
    { key: 'transactionId', label: 'transaction ID' },
    { key: 'amount', label: 'Amount' },
    { key: 'currency', label: 'Currency' },
    { key: 'depositStatus', label: 'deposit status' },
    { key: 'uuid', label: 'User ID' },
    { key: 'depositChannelName', label: 'Channel' },
    { key: 'thirdpartyId', label: 'Channel ID' },
    { key: 'createdAt', label: 'Create time' },
    { key: 'updatedAt', label: 'Update time' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  // 筛选项
  const channelName = ref({ channel_name: 'All', value: '-1' })
  const withdrawStatus = ref({ title: 'All', value: '-1' })
  const currency = ref({ code: 'BRL', name: 'BRL' })
  const timeType = ref({ label: 'CREATE', value: 'create' })
  const mode = ref({ title: 'TRANSACTION ID', value: '1' })
  const searchKey = ref(null)
  // 筛选项中的时间
  const timeRange = ref([])
  const timeRangeDefault = ref([])
  // *取消默认时间*
  // timeRangeDefault.value = setDatetimeDefault(1, 1)
  // timeRange.value = handlerTimestamp(
  //   timeRangeDefault.value[0],
  //   timeRangeDefault.value[1],
  //   'America/Sao_Paulo',
  // )
  // 时区 下载时用
  const timezone = ref('')

  const depositStatusOption = ref({})
  const withdrawStatusOption = ref([])
  const timeUpdate = ref([])
  const timeUpdateDefault = ref([])
  timeUpdateDefault.value = setDatetimeDefault(1, 1)
  timeUpdate.value = handlerTimestamp(
    timeUpdateDefault.value[0],
    timeUpdateDefault.value[1],
    'America/Sao_Paulo',
  )
  const depositList = ref([])
  // 渠道列表
  const channelNameOption = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refDepositListTable.value ? refDepositListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
      totalAmount: totalAmount.value,
    }
  })

  const refetchData = () => {
    refDepositListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  // 获取钱包状态
  const fetchWalletStatusOption = () => {
    store.dispatch('deposit/fetchWalletStatus').then(response => {
      if (response.data.code == 200) {
        const { data } = response.data
        depositStatusOption.value = handlerDepositStatusData(data)
        withdrawStatusOption.value = handlerWithdrawStatusData(data)
        store.state.deposit.depositStatusOption = handlerDepositStatusData(data)
        store.state.deposit.withdrawStatusOption = handlerWithdrawStatusData(data)
      }
    }).catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  // 充值列表参数
  const getDepositParams = () => {
    const params = {
      startDate: null,
      endDate: null,
      depositStatus: Number(withdrawStatus.value.value),
      model: Number(mode.value.value),
      searchKey: trim(searchKey.value),
      depositChannelName: channelName.value.value,
      pageNo: currentPage.value,
      pageSize: perPage.value,
      currency: currency.value.code,
    }
    if ((params.model === 4 || params.model === 5) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      if (timeType.value.value === 'create') {
        [params.startDate, params.endDate] = timeRange.value
        params.updatedAtBegin = null
        params.updatedAtEnd = null
      } else if (timeType.value.value === 'update') {
        [params.updatedAtBegin, params.updatedAtEnd] = timeRange.value
        params.startDate = null
        params.endDate = null
      }
    }
    return params
  }
  // 获取充值列表
  const fetchDepositList = () => {
    Vue.prototype.$showLoading()
    const params = getDepositParams()
    store.dispatch('deposit/fetchDepositList', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code === 200 && response.data.data) {
        const { list, page, extData } = response.data.data
        depositList.value = list
        totalInvoices.value = page.tatalCount
        totalAmount.value = extData.totalAmount
      } else if (response.data.code === 200 && !response.data.data) {
        depositList.value = []
        totalInvoices.value = 0
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  // 获取渠道列表
  const fetchChannelList = () => {
    Vue.prototype.$showLoading()
    store.dispatch('deposit/fetchChannelList').then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code === 200) {
        channelNameOption.value = response.data.data
        channelNameOption.value = channelNameOption.value.map(option => {
          // eslint-disable-next-line no-param-reassign
          option.value = option.channel_name
          return option
        })
        const defaultAll = [{ channel_name: 'All', value: '-1' }]
        channelNameOption.value = defaultAll.concat(channelNameOption.value)
        channelName.value = { channel_name: 'All', value: '-1' }
        store.state.deposit.channelNameOption = channelNameOption.value
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  // 下载参数
  const getDownloadParams = () => {
    const params = {
      searchKey: trim(searchKey.value),
      model: Number(mode.value.value),
      depositStatus: Number(withdrawStatus.value.value),
      startDate: null,
      endDate: null,
      pageNo: currentPage.value,
      pageSize: perPage.value,
      timezone: timezone.value,
      currency: currency.value.code,
    }
    if ((params.model === 4 || params.model === 5) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if (timeRange.value.length > 0) {
      if (timeType.value.value === 'create') {
        [params.startDate, params.endDate] = timeRange.value
        params.updatedAtBegin = null
        params.updatedAtEnd = null
      } else if (timeType.value.value === 'update') {
        [params.updatedAtBegin, params.updatedAtEnd] = timeRange.value
        params.startDate = null
        params.endDate = null
      }
    }
    return params
  }
  // 下载
  const depositDownload = cb => {
    Vue.prototype.$showLoading()
    const params = getDownloadParams()
    store.dispatch('deposit/download', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code == 200) {
        cb()
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  if (!store.state.deposit.depositStatusOption && !store.state.deposit.withdrawStatusOption) {
    fetchWalletStatusOption()
  } else {
    depositStatusOption.value = store.state.deposit.depositStatusOption
    withdrawStatusOption.value = store.state.deposit.withdrawStatusOption
  }
  if (!store.state.deposit.channelNameOption) {
    fetchChannelList()
  } else {
    channelNameOption.value = store.state.deposit.channelNameOption
    channelName.value = { channel_name: 'All', value: '-1' }
  }
  // fetchDepositList()
  const handlerWithdrawStatusData = data => {
    if (!data) return
    const options = []
    data.forEach(obj => {
      const o = {}
      // eslint-disable-next-line prefer-destructuring
      o.value = Object.keys(obj)[0]
      o.title = obj[o.value]
      options.push(o)
    })
    // eslint-disable-next-line consistent-return
    return options
  }

  const handlerDepositStatusData = data => {
    if (!data) return
    const o = {}
    data.forEach(obj => {
      const value = Object.keys(obj)[0]
      o[value] = obj[value]
    })
    // eslint-disable-next-line consistent-return
    return o
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    refDepositListTable,

    depositStatusOption,
    withdrawStatusOption,

    searchKey,
    mode,
    withdrawStatus,
    channelName,
    timeRange,
    timeRangeDefault,
    timeUpdate,
    timeUpdateDefault,
    timezone,
    currency,
    timeType,

    depositList,
    fetchDepositList,
    depositDownload,
    channelNameOption,
  }
}
